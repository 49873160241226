var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('ul',{staticClass:"grid grid-cols-1 gap-5 mt-2 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3"},_vm._l(([
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        22,
        23,
        24 ]),function(i){return _c('li',{key:i},[_c('session-list-item',{attrs:{"loading":true}})],1)}),0):_vm._e(),(!_vm.loading)?_c('ul',{staticClass:"grid grid-cols-1 gap-5 mt-2 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3"},_vm._l((_vm.sessions),function(session){return _c('li',{key:session.sessionId},[_c('session-list-item',{attrs:{"session":session}})],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }