<template>
  <img
    class="w-12 h-12 rounded-full"
    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
    alt=""
  />
</template>

<script>
export default {
  name: "PlayerPicture",
};
</script>
