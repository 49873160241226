var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid w-full grid-cols-1 space-y-6 lg:grid-cols-3 xl:grid-cols-4 lg:space-y-0 lg:space-x-6"},[_c('div',{staticClass:"space-y-4 xl:col-span-2"},[_c('tailwind-card',{attrs:{"title":("Dernière session " + (_vm.session ? ' - ' + _vm.formattedDate : '')),"to":_vm.session ? ("/sessions/" + (_vm.session.sessionId)) : ''}},[_c('session-result-chart-container',{attrs:{"loading":_vm.loading,"results":_vm.session ? _vm.session.playerResults : [],"show-buy-ins":false}})],1),_c('div',{staticClass:"grid grid-cols-1 space-x-0 space-y-4 sm:space-y-0 sm:space-x-4 sm:grid-cols-2 xl:space-x-4 lg:grid-cols-1 lg:space-y-4 lg:space-x-0 xl:grid-cols-2 xl:space-y-0"},[_c('session-winner',{attrs:{"results":_vm.results}}),_c('session-loser',{attrs:{"results":_vm.results}})],1)],1),_c('div',[_c('h2',{staticClass:"pb-0 text-xl font-medium tracking-wide text-white"}),_c('ranking-component',{attrs:{"loading":_vm.loading,"rankings":_vm.rankings,"season-ranking":true,"title":("Classement " + _vm.season),"previous-rankings":_vm.session &&
        _vm.session.previousSession &&
        _vm.session.season.seasonId === _vm.session.previousSession.season.seasonId
          ? _vm.session.previousSession.rankings.filter(
              function (r) { return !r.rankingKey.general; }
            )
          : []}})],1),_c('div',[_c('ranking-component',{attrs:{"loading":_vm.loading,"rankings":_vm.rankings,"previous-rankings":_vm.session &&
        _vm.session.previousSession &&
        _vm.session.season.seasonId === _vm.session.previousSession.season.seasonId
          ? _vm.session.previousSession.rankings.filter(
              function (r) { return r.rankingKey.general; }
            )
          : [],"season-ranking":false,"title":"Classement général"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }